import React from "react"
import { Row, Col, Container, Carousel } from "react-bootstrap"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CatImage1 from "../components/CatImage1"
import CatImage2 from "../components/CatImage2"
import CatImage3 from "../components/CatImage3"
import CatImage4 from "../components/CatImage4"
import CatImage5 from "../components/CatImage5"
import CatImage6 from "../components/CatImage6"
import CatImage7 from "../components/CatImage7"
import CatImage8 from "../components/CatImage8"
import CatImage9 from "../components/CatImage9"
import CatImage10 from "../components/CatImage10"
import CatImage11 from "../components/CatImage11"
import CatImage12 from "../components/CatImage12"
import CatImage13 from "../components/CatImage13"
import CatImage14 from "../components/CatImage14"
import CatImage15 from "../components/CatImage15"
import CatImage16 from "../components/CatImage16"
import CatImage17 from "../components/CatImage17"
import CatImage18 from "../components/CatImage18"
import CatImage19 from "../components/CatImage19"
import CatImage20 from "../components/CatImage20"
import CatImage21 from "../components/CatImage21"
import CatImage22 from "../components/CatImage22"
import CatImage23 from "../components/CatImage23"
import Video from "../components/video"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }} style={{
        background: `#5E6D7A`,
      }}>
    <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />
    <Container className="text-center">
      <Row>
        <Col>
            <p className="content-text"><br/>&quot;In 20 Jahren wirst du mehr bedauern, was du nicht getan hast als das, was du getan hast.<br/>Also löse die Leinen, verlasse deinen sicheren Hafen.<br/> Lass den Wind in deine Segel wehen.<br/><strong>Erforsche! Träume! Entdecke!</strong>&quot; <br/> (Mark Twain)</p>
        </Col>
      </Row>
      <Row>
        <Col>
        <Video
          videoSrcURL="https://www.youtube.com/embed/oZV4v3M7eho"
          videoTitle="Bali 4.1 for sale"
          />
        </Col>
      </Row>
      <Row>
        <Col>
            <p align="left" className="content-text"><br/>Aktuell liegt unser Fokus auf dem Verkauf von <a href="https://www.boat24.com/de/gebrauchtboote/catcruise-gmbh/?abo=2362">gebrauchten Katamaranen.</a></p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
